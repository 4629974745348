import type VanillaCalendar from 'vanilla-calendar-pro';
import 'vanilla-calendar-pro/build/vanilla-calendar.min.css';
import { defineModule } from '@/js/utils/helpers';

const calendars: VanillaCalendar[] = [];

const getElements = () => ({
  inputElements:
    document.querySelectorAll<HTMLInputElement>('input[type="date"]'),
});

const getDateFiltersFromUrl = () =>
  ['filter_before', 'filter_after']
    .map((key) => new Date(new URLSearchParams(window.location.search).get(key) || ''))
    .filter((date) => !isNaN(date.getTime()))
    .sort((a, b) => a.getTime() - b.getTime()); 


const getPeriodSelected = () => {
  const [startDate, endDate] = getDateFiltersFromUrl();

  if (startDate && endDate) {
    return Array.from({ length: (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24) + 1 }, (_, i) => {
      const date = new Date(startDate);
      date.setDate(date.getDate() + i);
      return date;
    });
  }

  return [];
}

export default defineModule(
  async () => {
    const { inputElements } = getElements();
    if (!inputElements.length) return;

    const { default: VanillaCalendar } = await import('vanilla-calendar-pro');

    inputElements.forEach((inputElement) => {
      inputElement.type = 'text'; 
      const [startDate, endDate] = getDateFiltersFromUrl().map(date => date ? `${date.toLocaleDateString('en-CA')}` : '');
      if (startDate || endDate) {
        inputElement.value = `${[startDate, endDate].join(' / ')}`;
        const event = new CustomEvent('updatedDate', {detail: {startDate: startDate, endDate: endDate}});
        inputElement.dispatchEvent(event);
      }

      const calendar = new VanillaCalendar(inputElement, {
        input: true,
        type: 'multiple',
        months: 2,
        jumpMonths: 2,
        actions: {
          changeToInput(e, self) {
            if (!self.HTMLInputElement) return;
            if (self.selectedDates.filter(Boolean).length > 1) {
              // eslint-disable-next-line prefer-destructuring
              self.HTMLInputElement.value = `${self.selectedDates[0]} / ${self.selectedDates.at(-1)}`;
              self.hide();
              const event = new CustomEvent('updatedDate', {detail: {startDate: `${self.selectedDates[0]}`, endDate: `${self.selectedDates.at(-1)}`}});
              self.HTMLInputElement.dispatchEvent(event);
            } else {
              self.HTMLInputElement.value = '';
            }
          },
        },
        settings: {
          selection: {
            day: 'multiple-ranged',
            
          },
          selected: {
            dates: getPeriodSelected()
          },
          visibility: {
            positionToInput: 'center',
            daysOutside: false,
          },
        },
      });

      calendar.init();

      calendars.push(calendar);
    });
  },
  () => {
    while (calendars.length) {
      calendars.pop()?.destroy();
    }
  },
);
