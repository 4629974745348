import debounce from 'lodash-es/debounce';
import { defineModule } from '../../utils/helpers';
import { createLoadMore } from '../../utils/load-more';

const loadMores: ReturnType<typeof createLoadMore>[] = [];

const getElements = () => ({
  loadMoreElements: document.querySelectorAll<HTMLElement>('[data-load-more]'),
});

const debouncedOnInputFilterChange = debounce(
  async (
    { currentTarget }: Event,
    loadMore: ReturnType<typeof createLoadMore>,
  ) => {
    const inputElement = <HTMLInputElement>currentTarget;

    loadMore.setFilters((filters) => ({
      ...filters,
      [inputElement.name]: inputElement.value,
    }));

    await loadMore.reset();
  },
  500,
);

const onInputFilterChange = async (
  event: Event,
  loadMore: ReturnType<typeof createLoadMore>,
) => {
  await debouncedOnInputFilterChange(
    {
      ...event,
      currentTarget: event.currentTarget,
    },
    loadMore,
  );
};

const onDatesFilterChange = async (
  event: CustomEvent,
  loadMore: ReturnType<typeof createLoadMore>,
) => {
  loadMore.setFilters((filters) => ({
    ...filters,
    after: event.detail.startDate,
    before: event.detail.endDate,
  }));

  await loadMore.reset();
};

const onSelectFilterChange = async (
  { currentTarget }: Event,
  loadMore: ReturnType<typeof createLoadMore>,
) => {
  const selectElement = <HTMLSelectElement>currentTarget;

  if (selectElement.multiple) {
    loadMore.setFilters((filters) => ({
      ...filters,
      [selectElement.name]: [...selectElement.selectedOptions].map(
        (selectedOption) => selectedOption.value,
      ),
    }));
  } else {
    loadMore.setFilters((filters) => ({
      ...filters,
      [selectElement.name]:
        selectElement.value === 'all' ? '' : selectElement.value,
    }));
  }

  await loadMore.reset();
};

export default defineModule(
  () => {
    const { loadMoreElements } = getElements();

    loadMoreElements.forEach((loadMoreElement) => {
      const { loadMoreTarget: targetSelector } = loadMoreElement.dataset;
      if (!targetSelector) return;

      const targetElement = document.querySelector<HTMLElement>(targetSelector);
      if (!targetElement) return;

      const {
        loadMorePostTypeSlug: postTypeSlug,
        loadMoreCategory: category = undefined,
        loadMorePerPage: perPage = '1',
        loadMoreOrderBy: orderBy,
        loadMoreOrder: order,
        loadMoreFilters: filtersSelector,
      } = loadMoreElement.dataset;

      const loadMore = createLoadMore({
        targetElement,
        triggerElement: loadMoreElement,
        options: {
          postTypeSlug,
          category,
          perPage: parseInt(perPage),
          orderBy,
          order,
        },
      });

      if (filtersSelector) {
        const filterElements = document.querySelectorAll<
          HTMLInputElement | HTMLSelectElement
        >(`${filtersSelector} .filters :where(input,select)`);
        filterElements.forEach((filterElement) => {
          if (filterElement instanceof HTMLInputElement) {
            if (filterElement.name === 'dates') {
              filterElement.addEventListener('updatedDate', async (e) => {
                const customEvent = e as CustomEvent;
                await onDatesFilterChange(customEvent, loadMore);
              });
            } else {
              filterElement.addEventListener('input', async (e) => {
                await onInputFilterChange(e, loadMore);
              });
            }
          } else if (filterElement instanceof HTMLSelectElement) {
            filterElement.addEventListener('change', async (e) => {
              await onSelectFilterChange(e, loadMore);
            });
          }
        });
      }

      loadMore.reset();
      loadMores.push(loadMore);
    });
  },
  () => {
    while (loadMores.length > 0) {
      loadMores.pop()?.destroy();
    }
  },
);
