import type { MultipleSelectInstance } from 'multiple-select-vanilla';
import type { autoUpdate } from '@floating-ui/dom';
import { defineModule } from '@/js/utils/helpers';

const selects: MultipleSelectInstance[] = [];
const autoUpdateCleanups: ReturnType<typeof autoUpdate>[] = [];

const getElements = () => ({
  selectElements: document.querySelectorAll<HTMLSelectElement>('select'),
});

export default defineModule(
  async () => {
    const { selectElements } = getElements();
    if (!selectElements.length) return;

    const { multipleSelect } = await import('multiple-select-vanilla');

    selectElements.forEach((selectElement) => {
      const select = multipleSelect(selectElement, {
        placeholder: '​', // Zero-width space,
        selectAll: false,
        hideOptgroupCheckboxes: true,
        minimumCountSelected: Infinity,
        filter: selectElement.multiple && selectElement.options.length >= 5,
        filterPlaceholder:
          selectElement.getAttribute('filter-placeholder') ?? '',
        noMatchesFoundText:
          selectElement.getAttribute('no-matches-found') ?? '',
        onAfterCreate: async () => {
          const parent = selectElement.nextElementSibling;
          if (!parent || !parent.classList.contains('ms-parent')) return;

          const multiSelectButton =
            parent.querySelector<HTMLElement>('.ms-choice');
          const multiSelectDrop = parent.querySelector<HTMLElement>('.ms-drop');
          if (!multiSelectButton || !multiSelectDrop) return;

          const { computePosition, autoUpdate, offset, size, shift } =
            await import('@floating-ui/dom');

          const updatePosition = () => {
            computePosition(multiSelectButton, multiSelectDrop, {
              strategy: 'fixed',
              placement: 'bottom-start',
              middleware: [
                offset(6),
                size({
                  apply({ rects, elements }) {
                    Object.assign(elements.floating.style, {
                      width: `${rects.reference.width}px`,
                    });
                  },
                }),
                shift(),
              ],
            }).then(({ x, y }) => {
              Object.assign(multiSelectDrop.style, {
                left: `${x}px`,
                top: `${y}px`,
              });
            });
          };

          autoUpdateCleanups.push(
            autoUpdate(multiSelectButton, multiSelectDrop, updatePosition),
          );
        },
      }) as MultipleSelectInstance;

      //   Prevents a bug where search text was already visible?
      select.close();

      selects.push(select);
    });
  },
  () => {
    while (selects.length) {
      selects.pop()?.destroy();
    }
    while (autoUpdateCleanups.length) {
      autoUpdateCleanups.pop()?.();
    }
  },
);
