import { observer as aosObserver } from '../vendor/aos';
import { AJAX_API } from './api';
import { isFunction } from './helpers';

type LoadMoreFilters = Record<string, string | string[] | undefined>;

interface LoadMoreOptions {
  triggerElement: HTMLElement;
  targetElement: HTMLElement;
  options?: {
    initialPage?: number;
    postTypeSlug?: string;
    category?: string;
    perPage?: number;
    orderBy?: string;
    order?: string;
    filters?: LoadMoreFilters;
  };
}

interface LoadMoreResponse {
  max: number;
  count: number;
  html: string;
}

const LOADING_CLASS = 'loading';
const COMPLETED_CLASS = 'completed';

export const createLoadMore = ({
  triggerElement,
  targetElement,
  options: {
    initialPage = 1,
    postTypeSlug = 'posts',
    category,
    perPage = 1,
    orderBy = 'date',
    order = 'DESC',
    filters = {},
  } = {},
}: LoadMoreOptions) => {
  let currentPage = initialPage;
  let abortController = new AbortController();
  
  const currentUrlParams = new URLSearchParams(window.location.search);
  
  currentUrlParams.forEach((value, key) => {
    if (!value) return;
    filters[key] = value;
  })

  const filtersToSearchParams = () =>
    Object.entries(filters)
      .reduce((params, [key, value]) => {
        // "filter_" is added before each query key, otherwise wordpress redirects to the /author/{authorName}/ url on a refresh
        const prefixedKey = key.startsWith('filter_') ? key : `filter_${key}`;

        if (value && (!Array.isArray(value) || value.length)) {
          params.set(prefixedKey, Array.isArray(value) ? value.join(',') : value);
        } else {
          params.delete(prefixedKey);
        }
         
        return params;
      }, new URLSearchParams())
      .toString()
      .replace(/%2C/g, ',');

  const loadMore = async () => {
    currentPage += 1;

    triggerElement.setAttribute('disabled', 'true');
    triggerElement.classList.add(LOADING_CLASS);

    try {
      const { max, html } = await AJAX_API.options({
        signal: abortController.signal,
      })
        .formData({
          action: 'load_more',
          post_type_slug: postTypeSlug,
          category,
          per_page: perPage,
          page: currentPage,
          orderby: orderBy,
          order,
          filters: filtersToSearchParams(),
        })
        .post()
        .json<LoadMoreResponse>();

      const originalChildrenCount = targetElement.childElementCount;
      targetElement.insertAdjacentHTML('beforeend', html);
      [...targetElement.children]
        .slice(originalChildrenCount - targetElement.childElementCount)
        .forEach(
          (insertedItem) =>
            insertedItem.hasAttribute('data-aos') &&
            aosObserver.observe(insertedItem),
        );

      if (!max || currentPage >= max) {
        triggerElement.setAttribute('disabled', 'true');
        triggerElement.classList.add(COMPLETED_CLASS);
      } else {
        triggerElement.setAttribute('disabled', 'false');
        triggerElement.classList.remove(COMPLETED_CLASS);
      }
    } catch (error) {
      currentPage -= 1;
    } finally {

      if (!triggerElement.classList.contains(COMPLETED_CLASS)) {
        triggerElement.removeAttribute('disabled');
        triggerElement.classList.remove(LOADING_CLASS);
      }
    }
  };

  triggerElement.addEventListener('click', loadMore);

  return {
    loadMore,
    setFilters: (
      nextFilters:
        | ((filters: LoadMoreFilters) => LoadMoreFilters)
        | LoadMoreFilters,
    ) => {
      filters = isFunction(nextFilters) ? nextFilters(filters) : nextFilters;
      const searchParams = filtersToSearchParams();

      window.history.pushState(
        {},
        '',
        `${window.location.pathname}${searchParams ? `?${searchParams}` : ''}`,
      );
    },
    reset: async () => {
      targetElement.classList.remove(COMPLETED_CLASS);

      abortController.abort();
      abortController = new AbortController();
      currentPage = 0;

      while (targetElement.firstChild) {
        targetElement.removeChild(targetElement.firstChild);
      }

      await loadMore();
    },
    destroy: () => {
      abortController.abort();
      triggerElement.removeEventListener('click', loadMore);
    },
  };
};
